import Vue from "vue";
import Vuex from "vuex";
import config from "./modules/config";
import session from "./modules/session";
import itemEdit from "./modules/itemEdit";
import notify from "./modules/notify";
import designacion from "./modules/designacion";
import postulacion from "./modules/postulacion";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    session,
    config,
    itemEdit,
    postulacion,
    notify,
    designacion,
  },
});
