import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VueEditor } from "vue2-editor";
import { apolloProvider } from "./configs/apolloConfig";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VuePdfApp from "vue-pdf-app";
import vSelect from "vue-select";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBookOpenReader,
  faTrashCan,
  faCheck,
  faPaperPlane,
  faPlus,
  faTriangleExclamation,
  faFileExcel,
  faPrint,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.config.productionTip = false;
library.add(
  faBookOpenReader,
  faTriangleExclamation,
  faTrashCan,
  faCheck,
  faPaperPlane,
  faPlus,
  faFileExcel,
  faPrint,
  faFilePdf
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
//Importar estilos css
import "./configs/loaderCssStyles";

//Importar componentes de complementos
Vue.component("vue-editor", VueEditor);
Vue.component("vue-pdf-app", VuePdfApp);
Vue.component("v-select", vSelect);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  apolloProvider,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
