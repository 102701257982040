<template>
   <div id="app">
      <router-view/>
      <AppNotifyVue/>
   </div>
</template>
<script>
import AppNotifyVue from './components/AppNotify.vue'
export default {
   name: 'AppHome',
   components: {
      AppNotifyVue
   }
}
</script>