<template>
  <div>
    <div class="titulo mt-3">Publicaciones</div>

    <b-card class="card-inf mx-5">
      <b-row>
        <b-col cols="5">
          <v-select :options="anios" v-model="gestion">
            <template #header>
              <div style="opacity: 0.8">Seleccione año de publicación:</div>
            </template>
          </v-select>
        </b-col>
      </b-row>
    </b-card>

    <b-row cols-lg="2" cols-md="1">
      <PublicadasConvocatorias
        v-for="(pub, index) in ediPublicadas"
        :key="index"
        :publicada="pub"
      />
    </b-row>
  </div>
</template>

<script>
import PublicadasConvocatorias from "./PublicadasConvocatorias.vue";
import { EDICIONES_PUBLICADAS } from "@/graphql/querys/inicio";
export default {
  name: "IndexSoporte",
  data() {
    return {
      gestion: null,
      anios: [],
    };
  },
  components: {
    PublicadasConvocatorias,
  },
  computed: {
    devolverAnio() {
      return this.gestion ? parseInt(this.gestion) : null;
    },
  },
  apollo: {
    ediPublicadas: {
      query: EDICIONES_PUBLICADAS,
      variables() {
        return {
          gestion: this.devolverAnio,
        };
      },
      result(response) {
        let data = response.data.ediPublicadas;
        if (data) {
          this.anios = data.map((item) => {
            let [an] = item.fechaPublicacion.split("-");
            return an;
          });
          this.anios = [...new Set(this.anios)];
        }
      },
    },
  },
};
</script>