import gql from "graphql-tag"

export const USER_SESSION = gql`{
   usuario:actualAdmin{
      id
      nombres
      apellidos
      uname
      rol
      activo
   }
}`