<template>
  <div class="position-sticky top-0">
    <div v-if="$apollo.loading" class="text-center">
      <b-spinner variant="success"></b-spinner>
    </div>
    <div v-else>
      <b-dropdown
        v-b-tooltip.hover
        title="Convocatorias"
        left
        :text="convocatoriaTematica"
        variant="primary"
        class="m-2"
      >
        <b-dropdown-item
          v-for="convo in convDesig"
          :key="convo.id"
          @click="selectConvocatoria(convo.id, convo.tematica)"
          >{{ convo.tematica }}</b-dropdown-item
        >
      </b-dropdown>
      <div class="titulo2">Comites Designados</div>
      <b-card
        v-for="(comi, index) in misComites"
        :key="index"
        :id="comi.id"
        class="mis-comites my-3"
        @click="cambiarClaseDeSeleccion(comi.id)"
      >
        <b-row align-v="center">
          <div class="col-6">
            <b style="font-size: 1.2rem">{{ ETAPAS_REVISION[comi.comite] }}</b>
          </div>
          <div class="col-3">
            <b-card
              ><font-awesome-icon
                icon="fa-solid fa-book-open-reader"
                size="lg"
              />
              <span class="mx-2">{{
                comi.cantRevisionesDesignadas
              }}</span></b-card
            >
          </div>
        </b-row>
        <b-row align-v="center">
          <div class="col-12">
            <b style="font-size: 1.2rem"> Categoria:</b>
            <span class="mx-2">{{ comi.categoria.nombre }}</span>
          </div>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ETAPAS_REVISION } from "@/shared/utils/constants";
import {
  CONVOCATORIAS_DESIGNADAS,
  MIS_COMITES_DESIGNADOS,
} from "@/graphql/querys/inicio";
export default {
  name: "MisComites",
  data() {
    return {
      ETAPAS_REVISION,
      convocatoriaTematica: null,
      idConvocatoria: null,
    };
  },
  methods: {
    cambiarClaseDeSeleccion(idCard) {
      if (this.$store.getters.GET_ID_CLASS_ANTERIOR) {
        let anterior = document.getElementById(
          this.$store.getters.GET_ID_CLASS_ANTERIOR
        );
        anterior.classList.add("mis-comites");
        anterior.classList.remove("mis-comites-escogido");
        let nuevoCard = document.getElementById(idCard);
        nuevoCard.classList.add("mis-comites-escogido");
        nuevoCard.classList.remove("mis-comites");

        this.$store.commit("SET_ID_CLASS_ANTERIOR", idCard);
      } else {
        let nuevoCard = document.getElementById(idCard);
        this.$store.commit("SET_ID_CLASS_ANTERIOR", idCard);
        nuevoCard.classList.add("mis-comites-escogido");
        nuevoCard.classList.remove("mis-comites");
      }
      this.$store.commit("SET_ID_DESIGNATION_COMITE", idCard);
    },
    selectConvocatoria(id, tematica) {
      this.$store.commit("SET_ID_CLASS_ANTERIOR", null);
      this.$store.commit("SET_ID_DESIGNATION_COMITE", null);
      this.convocatoriaTematica = tematica;
      this.idConvocatoria = id;
    },
  },

  apollo: {
    convDesig: {
      query: CONVOCATORIAS_DESIGNADAS,
      result(response) {
        if (response.data.convDesig && response.data.convDesig.length > 0) {
          this.idConvocatoria = response.data.convDesig[0].id;
          this.convocatoriaTematica = response.data.convDesig[0].tematica;
        }
      },
    },
    misComites: {
      query: MIS_COMITES_DESIGNADOS,
      variables() {
        return {
          idConvocatoria: parseInt(this.idConvocatoria),
        };
      },

      skip() {
        return !this.idConvocatoria;
      },
    },
  },
  watch: {
    misComites(value) {
      if (!this.$store.getters.GET_ID_CLASS_ANTERIOR) {
        setTimeout(() => {
          //   console.log(value);
          this.cambiarClaseDeSeleccion(value[0].id);
        }, 500);
      } else {
        setTimeout(() => {
          //   console.log(value);
          this.cambiarClaseDeSeleccion(
            this.$store.getters.GET_ID_DESIGNATION_COMITE
          );
        }, 500);
      }
    },
  },
};
</script>