import { MESES } from "../utils/constants";

export const MixFormatDate = {
   filters: {
      formatDate(date){
         if( !date ) return;
         let [anio, mes, dia] = date.split('-');
         return `${dia} ${MESES[parseInt(mes)].substring(0,3)} ${anio}` 
      },
      formatDateTime(date){
         if( !date ) return;
         let fecha = date.split('T')[0]
         return fecha.split('-').reverse().join('/')
      },
      formatDateTimeHora(date){
         if( !date ) return;
         let [fecha, hora] = date.split('T')
         fecha = fecha.split('-').reverse().join('/')
         hora  = hora.substr(0,5)
         return `${fecha}, ${hora}`
      },
      formatFechaSlash(date) {
         if( !date ) return;
         return date.split("-").reverse().join("/");
      },
      formatMonthYear(date){
         if( !date ) return;
         let [anio, mes] = date.split('-')
         return `${MESES[parseInt(mes)]}, ${anio}` 
      }
   },
   methods:{
      orderDate(date){
         if(!date) return
         return date.split('-').reverse().join('/')
      }
   }
}