export default {
  state: {
    idDesignacionComite: null,
    idClassAnterior: null,
  },
  mutations: {
    SET_ID_DESIGNATION_COMITE(state, id) {
      state.idDesignacionComite = id;
    },
    SET_ID_CLASS_ANTERIOR(state, id) {
      state.idClassAnterior = id;
    },
  },
  getters: {
    GET_ID_DESIGNATION_COMITE: (state) => state.idDesignacionComite,
    GET_ID_CLASS_ANTERIOR: (state) => state.idClassAnterior,
  },
};
