<template>
    <b-nav-item-dropdown v-if="user" :text="user.nombres" right>
        <b-dropdown-item href="#">¡BIENVENIDO!</b-dropdown-item>
        <b-dropdown-item @click.native="closeSesion"
                  variant="danger">Cerrar Sesión</b-dropdown-item>
      </b-nav-item-dropdown>
      
</template>

<script>
import { outSesion } from "@/shared/utils/session";

export default {
   name: "UserSesion",
   computed: {
      user() {
         return this.$store.getters.GET_USER;
      },
   },
   methods: {
      closeSesion() {
         outSesion();
      },
   },
};
</script>