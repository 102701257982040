<template>
  <div v-if="$apollo.loading" class="text-center">
    <b-spinner variant="success"></b-spinner>
  </div>
  <div v-else-if="error" class="text-center">
    <span>Error, verifique su conexión y recargue la página</span>
  </div>
  <div v-else>
    <div class="home-app">
      <AppSideBarVue />
      <div class="container-section">
        <AppHeaderVue />
        <div
          v-if="
            $route.name == 'inicio' &&
            this.$store.getters.GET_LEVEL_USER == 'EVL'
          "
        >
          <InicioIndex />
        </div>
        <div
          v-if="
            $route.name == 'inicio' &&
            this.$store.getters.GET_LEVEL_USER != 'EVL'
          "
        >
          <InicioSoporte />
        </div>

        <router-view v-else></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import AppSideBarVue from "../components/layouts/AppSideBar.vue";
import AppHeaderVue from "../components/layouts/header/AppHeader.vue";
import { USER_SESSION } from "../graphql/querys/userSession";
import { outSesion } from "../shared/utils/session";
import InicioIndex from "@/views/Inicio/index.vue";
import InicioSoporte from "@/views/Inicio/inicioSoporte/index.vue";
export default {
  name: "index-vue",
  components: {
    AppSideBarVue,
    AppHeaderVue,
    InicioIndex,
    InicioSoporte,
  },
  data: () => ({
    error: null,
  }),
  apollo: {
    usuario: {
      query: USER_SESSION,
      result({ data }) {
        let user = data.usuario;
        if (user) {
          this.$store.commit("SET_USER", user);
        } else if (this.$route.name != "login") {
          outSesion();
        }
      },
      error(error) {
        this.error = error;
      },
    },
  },
};
</script>
