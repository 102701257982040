<template>
  <div class="sidebar" :class="{ 'sidebar-min': minMenu }">
    <div class="menu-logo">
      <img src="~@/assets/img/eje.png" />
      <h3 class="text-center">Revista Jurídica</h3>
    </div>
    <ul class="menu">
      <li class="menu-item">
        <router-link :to="{ name: 'inicio' }">
          <b-icon icon="house-door"></b-icon>
          <span class="menu-text-name">Inicio</span>
        </router-link>
      </li>
      <li class="menu-item" v-if="this.$store.getters.GET_LEVEL_USER != 'EVL'">
        <router-link :to="{ name: 'convocatorias' }">
          <b-icon icon="chat-right-text-fill"></b-icon>
          <span class="menu-text-name">Convocatorias</span>
        </router-link>
      </li>
      <li class="menu-item" v-if="this.$store.getters.GET_LEVEL_USER != 'EVL'">
        <router-link :to="{ name: 'administradores' }">
          <b-icon icon="person-fill"></b-icon>
          <span class="menu-text-name">Administradores</span>
        </router-link>
      </li>
      <li class="menu-item" v-if="this.$store.getters.GET_LEVEL_USER != 'EVL'">
        <router-link :to="{ name: 'avisos' }">
          <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
          <span class="menu-text-name">Avisos</span>
        </router-link>
      </li>
      <li class="menu-item" v-if="this.$store.getters.GET_LEVEL_USER != 'EVL'">
        <router-link :to="{ name: 'reportes' }">
          <font-awesome-icon icon="fa-solid fa-file-excel" />
          <span class="menu-text-name">Reportes</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  data: () => ({
    showSubMenu: false,
  }),
  computed: {
    minMenu() {
      return this.$store.getters.SET_SHOW_MENU;
    },
  },
  watch: {
    minMenu() {
      if (this.minMenu && this.showSubMenu) {
        this.showSubMenu = false;
      }
    },
  },
};
</script>
<style>
@import "../../assets/css/ui/sidebar.css";
</style>