export default {
  state: {
    idPostulacion: null,
  },
  mutations: {
    SET_ID_POSTULACION(state, idPostulacion) {
      state.idPostulacion = idPostulacion;
    },
  },
  getters: {
    GET_ID_POSTULACION: (state) => state.idPostulacion,
  },
};
