<template>
  <div class="col-12">
    <b-navbar id="navbar-dell" type="light" variant="light">
      <b-navbar-nav>
        <b-nav-item href="#">
          <b-icon
            @click="showMenu"
            :icon="minMenu ? 'arrow-bar-right' : 'arrow-bar-left'"
          ></b-icon>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="header-nav ml-auto">
        <UserSession />
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import UserSession from "./UserSession.vue";

export default {
  name: "appHeader",
  components: {
    UserSession,
  },
  computed: {
    minMenu() {
      return this.$store.getters.SET_SHOW_MENU;
    },
  },
  methods: {
    showMenu() {
      this.$store.commit("SET_SHOW_MENU");
    },
  },
  mounted() {
    let minMenu = localStorage.getItem("sidebar");
    if (minMenu) {
      this.showMenu();
    }
  },
};
</script>