export const TYPE_NOTIFY = {
  error: "Error",
  info: "Información",
  success: "Correcto",
  warning: "Advertencia",
};

export const ROLES_ACCESO = [
  { value: "ADM", text: "Administración" },
  { value: "EVL", text: "Evaluador" },
  { value: "SUP", text: "Soporte" },
];
export const ETAPAS_REVISION_LIST = [
  { value: "RDC", text: "Comité de Redacción" },
  { value: "CTF", text: "Comité Científico" },
  { value: "PAR", text: "Comité de Pares Temáticos" },
  { value: "EDT", text: "Comité Editorial" },
];

export const ETAPAS_REVISION = {
  RDC: "Comité de Redacción",
  CTF: "Comité Científico",
  PAR: "Comité de Pares Temáticos",
  EDT: "Comité Editorial",
};

export const ESTADO_DECISION = {
  ACP: "Aceptado",
  DEN: "Rechazado",
};

export const ESTADOS_REVISION = {
  PEN: "Revisión Pendiente",
  OBS: "Observado",
  DEN: "Rechazado",
  ACP: "Aceptado",
};
export const ESTADO_REVISION_LIST = [
  { value: "PEN", text: "Revisión Pendiente" },
  { value: "OBS", text: "Observado" },
  { value: "DEN", text: "Rechazado" },
  { value: "ACP", text: "Aceptado" },
];
export const ESTADOS_OBSERVACION = {
  PEN: "Pendiente",
  COR: "Corregida",
  SUB: "Subsanada",
};
export const GENEROS = {
  F: "Femenino",
  M: "Masculino",
  N: "No Dice/No Responde",
  O: "Otro",
};
export const GENEROS_LIST = [
  { value: "F", text: "Femenino" },
  { value: "M", text: "Masculino" },
  { value: "N", text: "No Dice/No Responde" },
  { value: "O", text: "Otro" },
];
export const PROFESIONES = {
  ES: "Estudiante",
  AB: "Abogado/a",
  SC: "Sociólogo/a",
  AT: "Antropólogo/a",
  PD: "Pedagogo/a",
  TS: "Trabajador/a Social",
  EC: "Economista",
  PO: "Otro",
};
export const PROFESIONES_LIST = [
  { value: "ES", text: "Estudiante" },
  { value: "AB", text: "Abogado/a" },
  { value: "SC", text: "Sociólogo/a" },
  { value: "AT", text: "Antropólogo/a" },
  { value: "PD", text: "Pedagogo/a" },
  { value: "TS", text: "Trabajador/a Social" },
  { value: "EC", text: "Economista" },
  { value: "PO", text: "Otro" },
];
export const GRADOS = {
  PG: "Estudiante de pre grado",
  LC: "Licenciatura",
  MG: "Maestría",
  DO: "Doctorado",
  PD: "Post Doctorado",
};
export const GRADOS_LIST = [
  { value: "PG", text: "Estudiante de pre grado" },
  { value: "LC", text: "Licenciatura" },
  { value: "MG", text: "Maestría" },
  { value: "DO", text: "Doctorado" },
  { value: "PD", text: "Post Doctorado" },
];
export const DEPARTAMENTOS = {
  CH: "Chuquisaca",
  LP: "La Paz",
  OR: "Oruro",
  PT: "Potosi",
  CB: "Cochabamba",
  TJ: "Tarija",
  BN: "Beni",
  SC: "Santa Cruz",
  PD: "Pando",
};
export const DEPARTAMENTOS_LIST = [
  { value: "LP", text: "La Paz" },
  { value: "CH", text: "Chuquisaca" },
  { value: "OR", text: "Oruro" },
  { value: "PT", text: "Potosi" },
  { value: "CB", text: "Cochabamba" },
  { value: "TJ", text: "Tarija" },
  { value: "BN", text: "Beni" },
  { value: "SC", text: "Santa Cruz" },
  { value: "PD", text: "Pando" },
];
export const DIAS = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];
export const MESES = [
  "",
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const CONF_TOOLBAR_EDITOR = [
  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  ["bold", "italic", "underline"],
  [
    { align: "" },
    { align: "center" },
    { align: "right" },
    { align: "justify" },
  ],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
];
