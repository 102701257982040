import Vue from "vue";
import VueRouter from "vue-router";
import { NAMEKEY } from "../configs/urlsConfig";
import Index from "../views/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/inicio",
    name: "inicio",
    component: Index,
    meta: { auth: true },
    children: [
      {
        path: "/observaciones/:idDesignacionRevision",
        name: "observaciones",
        component: () => import("@/views/gestion_observaciones/"),
      },
      {
        path: "/destinatarios/:idAviso",
        name: "destinatarios",
        component: () => import("@/views/destinatariosAvisos/"),
      },
      {
        path: "/administradores",
        name: "administradores",
        component: () => import("@/views/administradores/"),
      },
      {
        path: "/reportes",
        name: "reportes",
        component: () => import("@/views/reportes/"),
      },
      {
        path: "/avisos",
        name: "avisos",
        component: () => import("@/views/avisos/"),
      },
      {
        path: "/convocatorias",
        name: "convocatorias",
        component: () => import("@/views/convocatorias/"),
      },
      {
        path: "/comites/:idConvocatoria",
        name: "comites",
        component: () => import("@/views/comites/"),
      },
      {
        path: "/categorias/:idConvocatoria",
        name: "categorias",
        component: () => import("@/views/categorias/"),
      },
      {
        path: "/postulaciones/:idConvocatoria",
        name: "postulaciones",
        component: () => import("@/views/postulaciones/"),
      },
      {
        path: "/editar-participante/:idParticipante",
        name: "participanteEditar",
        component: () => import("@/views/postulaciones/Participante/"),
      },
      {
        path: "/view-document/:idUploadProcess",
        name: "view-document",
        component: () => import("@/views/documentos/viewDocument.vue"),
      },
      {
        path: "/postulacion/:idPostulacion",
        name: "postulacion",
        component: () => import("@/views/postulaciones/Detalles.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let key = localStorage.getItem(NAMEKEY);
  let auth = to.matched.some((record) => record.meta.auth);
  if (!auth && to.path !== "/login") {
    next();
    return;
  }
  if (!key && to.path !== "/login") {
    next("login");
  } else if (!auth && key) {
    next("inicio");
  } else {
    next();
  }
});

export default router;
