<template>
  <div>
    <div
      v-if="
        $apollo.loading || this.$store.getters.GET_ID_DESIGNATION_COMITE == null
      "
      class="text-center"
    >
      <b-spinner variant="success"></b-spinner>
    </div>
    <div v-else>
      <b-dropdown
        v-b-tooltip.hover
        title="Estado Revision"
        left
        :text="
          nombreEstadoRevision == null
            ? 'Estado Revisión'
            : nombreEstadoRevision
        "
        variant="primary"
        class="m-2"
      >
        <b-dropdown-item
          v-for="(nombre, sigla) in ESTADOS_REVISION"
          :key="nombre"
          @click="selecEstado(sigla)"
          >{{ nombre }}</b-dropdown-item
        >
      </b-dropdown>

      <b-card
        v-for="(val, index) in misDesRevision"
        :key="index"
        class="my-3 mis-card"
      >
        <b-row>
          <div class="col-lg-4">
            <b-card-img
              v-if="
                val.postulacion.ultimaVersionDoc &&
                val.postulacion.ultimaVersionDoc.thumbnail
              "
              :src="URL_FILES + val.postulacion.ultimaVersionDoc.thumbnail"
              alt="Thumbnail"
              class="rounded-0"
            ></b-card-img>
            <img
              v-else
              src="~@/assets/img/default_doc.png"
              alt="Thumbnail"
              class="rounded-0 card-img"
            />
          </div>
          <div class="col-lg-8">
            <div class="titulo2 mb-2" style="color: #e23707">
              {{ val.postulacion.titulo }}
            </div>
            <span class="texto-nombre">
              {{
                val.postulacion.participante.nombres +
                " " +
                val.postulacion.participante.apellidos
              }}
            </span>
            <div>
              <b-badge :class="asBadgeClass(val.postulacion.etapaRevision)">{{
                ETAPAS_REVISION[val.postulacion.etapaRevision]
              }}</b-badge>
              <b-badge :class="asBadgeClass(val.postulacion.estadoRevision)">{{
                ESTADOS_REVISION[val.postulacion.estadoRevision]
              }}</b-badge>
            </div>
            <span class="texto-cuerpo">
              <b>Categoria: </b>
              {{ val.postulacion.categoria.nombre }}
            </span>
            <br />
            <div class="mt-3 text-center">
              <router-link
                :to="{
                  name: 'observaciones',
                  params: { idDesignacionRevision: val.id },
                }"
              >
                <b-button size="sm" class="btn-revisar"
                  ><span>Revisar</span></b-button
                ></router-link
              >
            </div>
          </div>
        </b-row>
      </b-card>
    </div>
    <b-modal centered v-model="showModal">
      <template #modal-title>
        <h4>Información Importante</h4>
      </template>
      <p class="my-4" style="font-size: 1.3rem">
        El estado Seleccionado no cuenta con datos actualmente.
      </p>
      <template #modal-footer>
        <div class="row">
          <b-col>
            <b-button
              variant="danger"
              size="sm"
              class="float-right"
              @click="showModal = !showModal"
            >
              Cancelar
            </b-button>
          </b-col>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { URL_FILES } from "@/configs/urlsConfig";
import { ESTADOS_REVISION, ETAPAS_REVISION } from "@/shared/utils/constants";
import { MIS_DESIGNACIONES_REVISION } from "@/graphql/querys/inicio";
export default {
  name: "MisDesigRevi",
  data() {
    return {
      ETAPAS_REVISION,
      URL_FILES,
      ESTADOS_REVISION,
      estadoRevision: "PEN",
      nombreEstadoRevision: null,
      showModal: false,
      ejeFun: false,
    };
  },
  methods: {
    selecEstado(sigla) {
      this.ejeFun = true;
      if (this.estadoRevision == sigla && this.misDesRevision.length == 0) {
        this.showModal = true;
      }
      this.estadoRevision = sigla;
    },
    asBadgeClass(cadena) {
      return "badge-" + cadena.toLowerCase();
    },
  },

  apollo: {
    misDesRevision: {
      query: MIS_DESIGNACIONES_REVISION,
      variables() {
        return {
          idDesignacionComite: parseInt(
            this.$store.getters.GET_ID_DESIGNATION_COMITE
          ),
          estado: this.estadoRevision,
        };
      },
      result(response) {
        if (
          response.data.misDesRevision &&
          response.data.misDesRevision.length > 0
        ) {
          this.nombreEstadoRevision = ESTADOS_REVISION[this.estadoRevision];
        } else {
          if (this.ejeFun) {
            this.showModal = true;
          }
        }
      },
      skip() {
        return !this.$store.getters.GET_ID_DESIGNATION_COMITE;
      },
    },
  },
};
</script>