export default {
   state: {
      user: null,
   },
   mutations: {
      SET_USER(state, user) {
         state.user = user
      }
   },
   getters: {
      GET_USER: state => state.user,
      GET_LEVEL_USER (state) {
         return state.user ? state.user.rol: null
      }
   }
}