import gql from "graphql-tag";
export const CONVOCATORIAS_DESIGNADAS = gql`
  query convocatoriasDesignadas {
    convDesig: convocatoriasDesignadas {
      id
      tematica
    }
  }
`;

export const MIS_COMITES_DESIGNADOS = gql`
  query misComitesDesignados($idConvocatoria: Int!) {
    misComites: misComitesDesignados(idConvocatoria: $idConvocatoria) {
      id
      comite
      cantRevisionesDesignadas
      categoria {
        id
        nombre
      }
    }
  }
`;

export const MIS_DESIGNACIONES_REVISION = gql`
  query misDesignacionesRevision($idDesignacionComite: Int!, $estado: String) {
    misDesRevision: misDesignacionesRevision(
      idDesignacionComite: $idDesignacionComite
      estado: $estado
    ) {
      id
      postulacion {
        id
        titulo
        etapaRevision
        estadoRevision

        participante {
          id
          nombres
          apellidos
        }
        categoria {
          id
          nombre
        }
        ultimaVersionDoc {
          thumbnail
        }
      }
    }
  }
`;

export const EDICIONES_PUBLICADAS = gql`
  query edicionesPublicadas($gestion: Int) {
    ediPublicadas: edicionesPublicadas(gestion: $gestion) {
      id
      tematica
      edicion
      fechaPublicacion
      finalFile
      thumbnail
    }
  }
`;
