<template>
   <div class="notify--box">
      <div v-for="(message, index) in messages" :key="index" class="notify-item" :class="'notify-' + message.type">
         <b @click="close(message.id)" class="notify-close">
             <b-icon icon="chevron-down"></b-icon>
         </b>
         <h4>{{ TYPE_NOTIFY[message.type] }}</h4>
         <p>{{ message.text }}</p>
      </div>
   </div>
</template>
<script>
import {TYPE_NOTIFY} from '@/shared/utils/constants'

export default {
   name: "AppNotify",
   data: () => ({
      TYPE_NOTIFY
   }),
   computed: {
      messages() {
         return this.$store.getters.GET_MESSAGES;
      }
   },
   methods: {
      close(id) {
         this.$store.commit("REMOVE_MESSAGE", id)
      }
   }
}
</script>
<style>
   @import '../assets/css/ui/notify.css';
</style>