<template>
  <b-col align-self="stretch" class="py-4 px-5" md="12">
    <b-card
      style="
        margin: auto;
        box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.2);
        height: 100%;
      "
    >
      <b-container class="p-0">
        <b-row>
          <b-col lg="6" md="6">
            <b-card-img
              v-if="publicada.finalFile && publicada.thumbnail"
              :src="URL_FILES + publicada.thumbnail"
              alt="Thumbnail"
              class="rounded-0 img-publicacion"
            ></b-card-img>
            <img
              v-else
              src="~@/assets/img/default_doc.png"
              alt="Thumbnail"
              class="rounded-0 card-img"
            />
          </b-col>
          <b-col lg="6" md="6">
            <h4 class="text-center" style="color: #4154f1">
              <b>{{ publicada.tematica }}</b>
            </h4>
            <hr />
            <b-card-sub-title class="mb-2"
              >Edición: {{ publicada.edicion }}</b-card-sub-title
            >

            <b-col class="mx-auto">
              <p><b>Fecha de Publicación:</b></p>
            </b-col>
            <b-col>
              {{ publicada.fechaPublicacion || formatDate }}
            </b-col>
            <div class="text-center mt-5">
              <a
                target="_blank"
                :href="URL_FILES + publicada.finalFile"
                class="count-box"
              >
                <font-awesome-icon icon="fa-solid fa-file-pdf" class="ic" />
                <span>Publicación</span>
              </a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-col>
</template>
<script>
import { MixFormatDate } from "@/shared/mixins/mixDates";
import { URL_FILES } from "@/configs/urlsConfig";

export default {
  name: "PublicadasConvocatorias",
  props: ["publicada"],
  mixins: [MixFormatDate],

  data() {
    return {
      URL_FILES,
    };
  },
};
</script>