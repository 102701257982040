<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-md-12">
        <MisComites />
      </div>
      <div class="col-xl-6 col-md-12">
        <MisDesigRevision />
      </div>
    </div>
  </div>
</template>

<script>
import MisComites from "@/views/Inicio/MisComites.vue";
import MisDesigRevision from "@/views/Inicio/MisDesigRevision.vue";
export default {
  name: "InicioIndex",
  components: {
    MisComites,
    MisDesigRevision,
  },
};
</script>